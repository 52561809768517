let $ = require('jquery');
window.$ = window.jQuery = $;
import popper from "popper.js";
import bootstrap from "bootstrap";


jQuery(function () {
    // при нажатии на кнопку scrollup
    jQuery('.scrollup').click(function () {
        // переместиться в верхнюю часть страницы
        jQuery("html, body").animate({
            scrollTop: 0
        }, 1000);
    })
})
// при прокрутке окна (window)
jQuery(window).scroll(function () {
    // если пользователь прокрутил страницу более чем на 200px
    if (jQuery(this).scrollTop() > 200) {
        // то сделать кнопку scrollup видимой
        jQuery('.scrollup').fadeIn();
    }
    // иначе скрыть кнопку scrollup
    else {
        jQuery('.scrollup').fadeOut();
    }
});

$(':button.button-1').click(function () {
    // собираем данные с формы
    var user_name = $('#exampleInputName').val();
    var user_tel = $('#exampleInputTel').val();
    var model = $('#Form1__Model').val();
    // отправляем данные
    $.ajax({
        url: "action.php", // куда отправляем
        type: "post", // метод передачи
        dataType: "json", // тип передачи данных
        data: { // что отправляем
            "user_name": user_name,
            "user_tel": user_tel,
            "model": model
        },
        // после получения ответа сервера
        success: function (data) {
            $('.toast-body').html(data.result);
            $('.toast').toast('show');
        }
    });
});

$(':button.button-2').click(function () {
    // собираем данные с формы
    var user_name = $('#exampleInputName2').val();
    var user_tel = $('#exampleInputTel2').val();
    var model = $('#Form2__Model').val();
    // отправляем данные
    $.ajax({
        url: "action.php", // куда отправляем
        type: "post", // метод передачи
        dataType: "json", // тип передачи данных
        data: { // что отправляем
            "user_name": user_name,
            "user_tel": user_tel,
            "model": model
        },
        // после получения ответа сервера
        success: function (data) {
            $('.toast-body').html(data.result);
            $('.toast').toast('show');
        }
    });
});